/**
 * Handles all data that is passed as URL search params during
 * initialization of PWA.
 */
export default {
    params: null,
    userFeatures: [],
    modulesWithTrainingStatus: [],
    moduleVersionMap: {},

    /**
     * Params are passed from native
     * using URL query parameters
     */
    Params: {
        LOCALE: "locale",
        STATION_CODE: "stationCode",
        USER_ID: "userId",
        COUNTRY: "country",
        REDIRECT_DATA: "redirectData",
        USER_EMPLOYEE_ID: "employeeId",
        USER_LOGIN: "userLogin"
    },

    /**
     * Parse preferences from URL Search Params and provide functions to
     * retrieve required data
     */
    parseSearchParams() {
        // Parse Search Parameters
        this.params = new URLSearchParams(window.location.search);
    },

    /**
     * Set user features.
     * @returns null
     */
    setUserFeatures(userFeatures) {
        this.userFeatures = userFeatures;
    },

    /**
     * Set module version map.
     * @returns null
     */
    setModuleVersions(moduleVersionMap) {
        this.moduleVersionMap = moduleVersionMap;
    },

    /**
     * Get user locale preference
     * @returns {string}
     */
    getUserLocale() {
        return (this.params &&
            this.params.get(this.Params.LOCALE)) ?
            this.params.get(this.Params.LOCALE) : "en";
    },

    /**
     * Get user's station code
     * @returns {string}
     */
    getStationCode() {
        return (this.params &&
            this.params.get(this.Params.STATION_CODE)) ?
            this.params.get(this.Params.STATION_CODE) : "";
    },

    /**
     * Get user Id
     * @returns {string}
     */
    getUserId() {
        return (this.params &&
            this.params.get(this.Params.USER_ID)) ?
            this.params.get(this.Params.USER_ID) : "";
    },

    /**
     * Get user's country
     * @returns {string}
     */
    getCountry() {
        return (this.params &&
            this.params.get(this.Params.COUNTRY)) ?
            this.params.get(this.Params.COUNTRY) : "";
    },

    /**
     * Get Redirect data passed to the module
     * @return {JSON|null}
     */
    getRedirectData() {
        return (this.params && JSON.parse(this.params.get(this.Params.REDIRECT_DATA)));
    },

    /**
     * Get user employee id.
     * @returns null
     */
    getUserEmployeeId() {
        return (this.params &&
            this.params.get(this.Params.USER_EMPLOYEE_ID)) ?
            this.params.get(this.Params.USER_EMPLOYEE_ID) : "";
    },

    /**
     * Get user employee login.
     * @returns null
     */
    getUserEmployeeLogin() {
        return (this.params &&
            this.params.get(this.Params.USER_LOGIN)) ?
            this.params.get(this.Params.USER_LOGIN) : "";
    },

    /**
     * set modules with training status
     */
    setModulesTrainingStatus(modulesWithTrainingStatus) {
        this.modulesWithTrainingStatus = modulesWithTrainingStatus;
    },

    /**
     * get training status for a module
     * @param moduleName
     * @returns {trainingStatus}
     */
    getTrainingStatus(moduleName) {
        const moduleWithTrainingStatus = this.modulesWithTrainingStatus.find(moduleWithTrainingStatus =>
            moduleWithTrainingStatus.moduleName == moduleName);
        if (moduleWithTrainingStatus) {
            return moduleWithTrainingStatus.trainingStatus;
        }
    }
};