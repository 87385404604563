import React, {Component} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import PropTypes from "prop-types";
import HeaderView from "../HeaderView";
import {Logger} from "@amzn/dolphin-web-framework";
import {getContainerId} from "../Utils/ScannerUtils";

class ScanWithCodeView extends Component {

    keyDown = (e) => {
        if (e.key === "Enter") {
            if(!this.scanRef.value) {
                Logger.log.info("Empty Scan received in ScanWithCodeView");
                return;
            }
            const data = getContainerId(this.scanRef.value);
            this.props.onSubmit(data);
            this.scanRef.value = "";
        }
    }

    requestFocus = (time) => {
        if (this.props.focusLock) {
            // Set read only true then false to avoid keyboard popping up
            setTimeout(() => {
                if (this.scanRef) {
                    this.scanRef.readOnly = true;
                    this.scanRef.focus();
                    this.scanRef.readOnly = false;
                }
            }, time);
        }
    }

    componentDidUpdate() {
        this.requestFocus(200);
    }

    render() {
        const {primaryTitle, secondaryTitle, image, button, checkbox} = this.props;
        return (
            <Column height="100%" heights="fill">
                <Column alignmentHorizontal="center">
                    <HeaderView primaryTitle={primaryTitle} secondaryTitle={secondaryTitle} />
                    <Row spacing={"none"} width="70%">
                        <img src={image} alt="Package Scan" height="fit-content" style={{width: '100%'}}/>
                    </Row>
                    {this.props.onSubmit &&
                    <div style={{zIndex: -1, position: "absolute", opacity: 0}}>
                        <input ref={(element) => {this.scanRef = element;}}
                               autoFocus={true}
                               autoComplete={"off"}
                               readOnly={"readOnly"}
                               type="text"
                               onKeyDown={this.keyDown}
                               onBlur={()=>this.requestFocus(20)}/>
                    </div>}
                    {checkbox &&
                    <Column alignmentHorizontal="left" width="100%" spacingInset="none none none medium">
                        <Checkbox checked={checkbox.checked} onChange={checkbox.onChange}>
                            {checkbox.label}
                        </Checkbox>
                    </Column>
                    }
                </Column>
                {button &&
                    <Column width="100%" alignmentVertical="bottom">
                        <Button onClick={button.onClick} size="large" type="secondary">{button.title}</Button>
                    </Column>
                }
            </Column>
        )
    }
}


ScanWithCodeView.propTypes = {
    primaryTitle: PropTypes.shape({
        title: PropTypes.string.isRequired,
        size: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
    secondaryTitle: PropTypes.shape({
        title: PropTypes.string.isRequired,
        size: PropTypes.string,
        type: PropTypes.string
    }),
    image: PropTypes.string.isRequired,
    button: PropTypes.shape({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }),
    onSubmit: PropTypes.func,
    focusLock: PropTypes.bool,
    checkbox: PropTypes.shape({
        label: PropTypes.string.isRequired,
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    })
}

export default ScanWithCodeView;